import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ReviewsCarousel() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    };

    const textStyle = {
        color: 'white',
        textAlign: 'center',
        maxWidth: '80%',  // limiting the width for better readability
        margin: '0 auto' // centers the text block horizontally
    };
    const ratingContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px'
    };
    const starsStyle = {
        color: 'gold',
        fontSize: '1.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const reviewsCountStyle = {
        color: 'white',
        textAlign: 'center',
        marginTop: '10px'
    };

    return (
        <div>
        <Slider {...settings}>
            <div style={containerStyle}>
                <p style={textStyle}>Elvin took very good care of Caramela and sent pictures frequently!! I definitely recommend and will love for Caramela to stay again with him! -- Laura J.</p>
            </div>
            <div style={containerStyle}>
                <p style={textStyle}>Elvin is a personal superhero! He followed instructions so well and my pup really enjoyed his walk with him -- Diana</p>
            </div>
            <div style={containerStyle}>
                <p style={textStyle}>Data has lots of fun with Jake in Elvin's place. Elvin is super responsible and sent lots of picture updates throughout the day. Definitely recommend! -- Yuting</p>
            </div>
        </Slider>

        <div style={ratingContainerStyle}>
                <span style={starsStyle}>★ ★ ★ ★ ★</span>
                <span style={reviewsCountStyle}>13 Rover reviews</span>
            </div>
        </div>
    );
}

export default ReviewsCarousel;
