import React from 'react';
import ReviewsCarousel from './ReviewsCarousel';
import {Button, Container, Typography, Grid, AppBar, Toolbar, Paper, IconButton, Link} from '@mui/material';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {CssBaseline} from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#FFCC00'
        },
        secondary: {
            main: '#ff9100',
        },
    },
});
const cardStyles = {
    padding: 20,
    transition: 'transform 0.3s',
    '&:hover': {
        transform: 'scale(1.2)',  // Zoom in effect on hover
    }
};

// Define card background colors for differentiation
const colors = ['#f48fb1', '#81d4fa', '#ffcc80'];

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <div style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/dog_gimp.png'})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
            }}>
                <Container style={{marginTop: 0}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{
                                marginTop: '5vh',
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                padding: '10px 20px',
                                borderRadius: '8px'
                            }}>
                                <Typography
                                    variant="h4"
                                    align="center"
                                    gutterBottom
                                    sx={{
                                        padding: '10px 20px',
                                        color: 'rgba(255, 255, 255, 0.8)',  // Making text semi-transparent
                                    }}>
                                    Welcome to Jersey City Dog Sitting
                                </Typography>
                                <Typography
                                    variant="body1"
                                    align="center"
                                    paragraph
                                    sx={{color: 'rgba(255, 255, 255, 0.8)'}}>
                                    70 Greene st., Jersey City, NJ 07302 (Available 24/7)
                                </Typography>
                                <ReviewsCarousel/>
                            </Paper>
                        </Grid>


                        <Grid item xs={12} md={4}>
                            <Paper elevation={2} sx={{
                                padding: '20px',
                                backgroundColor: '#f48fb1',
                                transition: 'transform 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.2)'
                                }
                            }}>
                                <Typography variant="h6" gutterBottom>
                                    Dog Walking ($25)
                                </Typography>
                                <Typography>
                                    Daily walks to ensure your pet gets the exercise they need.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper elevation={2} sx={{
                                padding: '20px',
                                backgroundColor: '#ffcc80',
                                transition: 'transform 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.2)'
                                }
                            }}>
                                <Typography variant="h6" gutterBottom>
                                    Day Care ($45)
                                </Typography>
                                <Typography>
                                    Safe and supervised environment for your pet to play and relax.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper elevation={2} sx={{
                                padding: '20px',
                                backgroundColor: '#81d4fa',
                                transition: 'transform 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.2)'
                                }
                            }}>
                                <Typography variant="h6" gutterBottom>
                                    Boarding ($60)
                                </Typography>
                                <Typography>
                                    Peace of mind knowing your pet is cared for while you're away.
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Link href="https://r.rover.com/S2ikta" target="_blank" rel="noopener noreferrer"
                              style={{marginLeft: '15px'}}>
                            <Button variant="contained" color="primary" size="large" style={{ marginTop: '10px' }}
                                    startIcon={<img src={`${process.env.PUBLIC_URL}/rover-icon.png`} alt="Rover logo"
                                                    style={{width: '24px', height: '24px'}}/>}>
                                Book with Rover
                            </Button>
                        </Link>
                    </Grid>
                </Container>
            </div>
        </ThemeProvider>
    );
}

export default App;
